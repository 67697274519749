// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
/*!
 * SPDX-FileCopyrightText: 2016 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.app-calendar .datepicker-button-section,
.app-calendar .today-button-section,
.app-calendar .view-button-section {
  display: flex;
}
.app-calendar .datepicker-button-section .button,
.app-calendar .today-button-section .button,
.app-calendar .view-button-section .button {
  border-radius: 0;
  font-weight: normal;
  margin: 0 0 var(--default-grid-baseline) 0;
  flex-grow: 1;
}
.app-calendar .datepicker-button-section .button:first-child:not(:only-of-type),
.app-calendar .today-button-section .button:first-child:not(:only-of-type),
.app-calendar .view-button-section .button:first-child:not(:only-of-type) {
  border-radius: var(--border-radius-element) 0 0 var(--border-radius-element);
}
.app-calendar .datepicker-button-section .button:last-child:not(:only-of-type),
.app-calendar .today-button-section .button:last-child:not(:only-of-type),
.app-calendar .view-button-section .button:last-child:not(:only-of-type) {
  border-radius: 0 var(--border-radius-element) var(--border-radius-element) 0;
}
.app-calendar .datepicker-button-section .button:not(:only-of-type):not(:first-child):not(:last-child),
.app-calendar .today-button-section .button:not(:only-of-type):not(:first-child):not(:last-child),
.app-calendar .view-button-section .button:not(:only-of-type):not(:first-child):not(:last-child) {
  border-radius: 0;
}
.app-calendar .datepicker-button-section .button:only-child,
.app-calendar .today-button-section .button:only-child,
.app-calendar .view-button-section .button:only-child {
  border-radius: var(--border-radius-element);
}
.app-calendar .datepicker-button-section .button:hover,
.app-calendar .datepicker-button-section .button:focus,
.app-calendar .datepicker-button-section .button.active,
.app-calendar .today-button-section .button:hover,
.app-calendar .today-button-section .button:focus,
.app-calendar .today-button-section .button.active,
.app-calendar .view-button-section .button:hover,
.app-calendar .view-button-section .button:focus,
.app-calendar .view-button-section .button.active {
  z-index: 50;
}
.app-calendar .datepicker-button-section__datepicker-label {
  flex-grow: 4 !important;
  text-align: center;
}
.app-calendar .datepicker-button-section__datepicker {
  margin-left: 26px;
  margin-top: 48px;
  position: absolute !important;
  width: 0 !important;
}
.app-calendar .datepicker-button-section__datepicker .mx-input-wrapper {
  display: none !important;
}
.app-calendar .datepicker-button-section__left, .app-calendar .datepicker-button-section__right {
  background-size: 10px;
  flex-grow: 0 !important;
  width: 34px;
  padding: 0 6px !important;
}
.app-calendar .datepicker-button-section__left {
  border-radius: var(--border-radius-element) 0 0 var(--border-radius-element) !important;
}
.app-calendar .datepicker-button-section__right {
  border-radius: 0 var(--border-radius-element) var(--border-radius-element) 0 !important;
}
.app-calendar .app-navigation__body {
  overflow-y: unset !important;
}
.app-calendar .app-navigation__body .app-navigation-header {
  padding: calc(var(--default-grid-baseline, 4px) * 2);
}
.app-calendar .new-event-today-view-section {
  display: flex;
}
.app-calendar .new-event-today-view-section .button {
  margin: 0 var(--default-grid-baseline) 0 0;
}
.app-calendar .new-event-today-view-section .new-event {
  flex-grow: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.app-calendar .new-event-today-view-section .today {
  flex-grow: 1;
  font-weight: normal !important;
}
.app-calendar .app-navigation-toggle {
  background-color: var(--color-main-background) !important;
  border: 1px solid var(--color-background-dark) !important;
}
.app-calendar .app-navigation button.icon-share {
  opacity: 0.3 !important;
}
.app-calendar .app-navigation button.icon-shared,
.app-calendar .app-navigation button.icon-public {
  opacity: 0.7 !important;
}
.app-calendar .app-navigation button.icon-share:active,
.app-calendar .app-navigation button.icon-share:focus,
.app-calendar .app-navigation button.icon-share:hover,
.app-calendar .app-navigation button.icon-shared:active,
.app-calendar .app-navigation button.icon-shared:focus,
.app-calendar .app-navigation button.icon-shared:hover,
.app-calendar .app-navigation button.icon-public:active,
.app-calendar .app-navigation button.icon-public:focus,
.app-calendar .app-navigation button.icon-public:hover {
  opacity: 1 !important;
}
.app-calendar .app-navigation #calendars-list {
  display: block !important;
}
.app-calendar .app-navigation li.app-navigation-loading-placeholder-entry div.icon.icon-loading {
  min-height: 44px;
}
.app-calendar .app-navigation .app-navigation-entry-wrapper.deleted .app-navigation-entry__name {
  text-decoration: line-through;
}
.app-calendar .app-navigation .app-navigation-entry-wrapper.open-sharing {
  box-shadow: inset 4px 0 var(--color-primary-element) !important;
  margin-left: -6px;
  padding-left: 6px;
}
.app-calendar .app-navigation .app-navigation-entry-wrapper.disabled .app-navigation-entry__name {
  color: var(--color-text-lighter) !important;
}
.app-calendar .app-navigation .app-navigation-entry-wrapper .app-navigation-entry__children .app-navigation-entry {
  padding-left: 0 !important;
}
.app-calendar .app-navigation .app-navigation-entry-wrapper .app-navigation-entry__children .app-navigation-entry .avatar {
  width: 32px;
  height: 32px;
  background-color: var(--color-border-dark);
  background-size: 16px;
}
.app-calendar .app-navigation .app-navigation-entry-wrapper .app-navigation-entry__children .app-navigation-entry .avatar.published {
  background-color: var(--color-primary-element);
  color: white;
}
.app-calendar .app-navigation .app-navigation-entry__multiselect {
  padding: 0 8px;
}
.app-calendar .app-navigation .app-navigation-entry__multiselect .multiselect {
  width: 100%;
  border-radius: var(--border-radius-large);
}
.app-calendar .app-navigation .app-navigation-entry__multiselect .multiselect__content-wrapper {
  z-index: 200 !important;
}
.app-calendar .app-navigation .app-navigation-entry__utils .action-checkbox__label {
  padding-right: 0 !important;
}
.app-calendar .app-navigation .app-navigation-entry__utils .action-checkbox__label::before {
  margin: 0 4px 0 !important;
}
.app-calendar .app-navigation .app-navigation-entry-new-calendar .app-navigation-entry__name {
  color: var(--color-text-maxcontrast) !important;
}
.app-calendar .app-navigation .app-navigation-entry-new-calendar:hover .app-navigation-entry__name, .app-calendar .app-navigation .app-navigation-entry-new-calendar--open .app-navigation-entry__name {
  color: var(--color-text-light) !important;
}
.app-calendar .app-navigation .app-navigation-entry-new-calendar .action-item:not(.action-item--open) .action-item__menutoggle:not(:hover):not(:focus):not(:active) {
  opacity: 0.5;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section div.multiselect {
  width: calc(100% - 14px);
  max-width: none;
  z-index: 105;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .oneline {
  white-space: nowrap;
  position: relative;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li {
  height: 44px;
  white-space: normal;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li .username {
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup {
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > a:hover,
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > a:focus,
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > .share-menu > a:hover,
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > .share-menu > a:focus {
  box-shadow: none !important;
  opacity: 1 !important;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > .icon:not(.hidden),
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > .share-menu .icon:not(.hidden) {
  padding: 14px;
  height: 44px;
  width: 44px;
  opacity: 0.5;
  display: block;
  cursor: pointer;
}
.app-calendar .app-navigation ul > li.app-navigation-entry-wrapper div.sharing-section .shareWithList > li > .sharingOptionsGroup > .share-menu {
  position: relative;
  display: block;
}
.app-calendar .app-navigation ul .appointment-config-list .app-navigation-caption {
  margin-top: 22px;
}
.app-calendar .app-navigation ul .appointment-config-list .app-navigation-entry-link,
.app-calendar .app-navigation ul .appointment-config-list .app-navigation-entry-link * {
  cursor: default;
}

/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.app-calendar .app-sidebar .editor-invitee-list-empty-message,
.app-calendar .app-sidebar .editor-reminders-list-empty-message,
.app-calendar .app-sidebar .editor-invitee-list-no-email-configured-message,
.event-popover .event-popover__inner .editor-invitee-list-empty-message,
.event-popover .event-popover__inner .editor-reminders-list-empty-message,
.event-popover .event-popover__inner .editor-invitee-list-no-email-configured-message {
  margin-top: 20px;
}
.app-calendar .app-sidebar .editor-invitee-list-empty-message__icon,
.app-calendar .app-sidebar .editor-reminders-list-empty-message__icon,
.app-calendar .app-sidebar .editor-invitee-list-no-email-configured-message__icon,
.event-popover .event-popover__inner .editor-invitee-list-empty-message__icon,
.event-popover .event-popover__inner .editor-reminders-list-empty-message__icon,
.event-popover .event-popover__inner .editor-invitee-list-no-email-configured-message__icon {
  background-size: 50px;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  opacity: 0.5;
}
.app-calendar .app-sidebar .editor-invitee-list-empty-message__caption,
.app-calendar .app-sidebar .editor-reminders-list-empty-message__caption,
.app-calendar .app-sidebar .editor-invitee-list-no-email-configured-message__caption,
.event-popover .event-popover__inner .editor-invitee-list-empty-message__caption,
.event-popover .event-popover__inner .editor-reminders-list-empty-message__caption,
.event-popover .event-popover__inner .editor-invitee-list-no-email-configured-message__caption {
  margin-top: 8px;
  text-align: center;
  color: var(--color-text-lighter);
}
.app-calendar .app-sidebar .editor-invitee-list-no-email-configured-message__icon,
.event-popover .event-popover__inner .editor-invitee-list-no-email-configured-message__icon {
  font-size: 50px;
  line-height: 1em;
  user-select: none;
}
.app-calendar .app-sidebar .editor-reminders-list-new-button,
.event-popover .event-popover__inner .editor-reminders-list-new-button {
  width: 100%;
  background-position-x: 8px;
}
.app-calendar .app-sidebar .app-sidebar-header__action,
.event-popover .event-popover__inner .app-sidebar-header__action {
  margin-top: 0 !important;
  max-height: none !important;
  flex-wrap: wrap;
}
.app-calendar .app-sidebar .app-sidebar-header__action div,
.event-popover .event-popover__inner .app-sidebar-header__action div {
  flex-shrink: 0;
}
.app-calendar .app-sidebar .app-sidebar-header__desc,
.event-popover .event-popover__inner .app-sidebar-header__desc {
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.app-calendar .app-sidebar .app-sidebar-header__desc .app-sidebar-header__menu,
.event-popover .event-popover__inner .app-sidebar-header__desc .app-sidebar-header__menu {
  z-index: 1;
}
.app-calendar .app-sidebar .app-sidebar-header__description,
.event-popover .event-popover__inner .app-sidebar-header__description {
  flex-direction: column;
  padding-top: 5px;
}
.app-calendar .app-sidebar .app-sidebar-tab,
.event-popover .event-popover__inner .app-sidebar-tab {
  overflow: unset !important;
  max-height: unset !important;
  height: auto !important;
}
.app-calendar .app-sidebar .app-sidebar-tab__buttons,
.event-popover .event-popover__inner .app-sidebar-tab__buttons {
  position: fixed;
  bottom: var(--body-container-margin);
  z-index: 2;
  width: calc(27vw - 11px);
  min-width: 289px;
  max-width: 489px;
  background-color: var(--color-main-background);
  border-radius: 0 0 var(--body-container-radius) 0;
  padding: 0 8px 6px 0;
}
.app-calendar .app-sidebar .app-sidebar-tab__buttons button,
.event-popover .event-popover__inner .app-sidebar-tab__buttons button {
  width: 100%;
  height: 44px;
}
.app-calendar .app-sidebar .app-sidebar-tab__content,
.event-popover .event-popover__inner .app-sidebar-tab__content {
  margin-bottom: 120px;
}
.app-calendar .app-sidebar .app-sidebar-tabs .empty-content,
.event-popover .event-popover__inner .app-sidebar-tabs .empty-content {
  height: 100vh;
  padding: 0 20px;
}
.app-calendar .app-sidebar .property-title-time-picker-loading-placeholder,
.event-popover .event-popover__inner .property-title-time-picker-loading-placeholder {
  width: 100%;
}
.app-calendar .app-sidebar .property-title-time-picker-loading-placeholder__icon,
.event-popover .event-popover__inner .property-title-time-picker-loading-placeholder__icon {
  margin: 0 auto;
  height: 62px;
  width: 62px;
  background-size: 62px;
}
.app-calendar .app-sidebar .app-sidebar__loading-indicator,
.event-popover .event-popover__inner .app-sidebar__loading-indicator {
  width: 100%;
  margin-top: 20vh;
}
.app-calendar .app-sidebar .app-sidebar__loading-indicator__icon,
.event-popover .event-popover__inner .app-sidebar__loading-indicator__icon {
  margin: 0 auto;
  height: 44px;
  width: 44px;
  background-size: 44px;
}
.app-calendar .app-sidebar .repeat-option-set,
.event-popover .event-popover__inner .repeat-option-set {
  flex-wrap: nowrap;
  align-items: baseline;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set__repeat,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set__repeat {
  flex-wrap: nowrap;
  gap: 10px;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set__repeat-field,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set__repeat-field {
  flex: 1 1 150px;
  min-width: 150px;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set__end-repeat,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set__end-repeat {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set__end-repeat .repeat-option-set__end-repeat-field,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set__end-repeat .repeat-option-set__end-repeat-field {
  flex: 1 1 150px;
  min-width: 350px;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set-section:not(:first-of-type),
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set-section:not(:first-of-type) {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set-section--on-the-select,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set-section--on-the-select {
  display: flex;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set-section--on-the-select .v-select,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set-section--on-the-select .v-select {
  width: 100%;
  min-width: 100px !important;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set-section__title,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set-section__title {
  list-style: none;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set-section__grid,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set-section__grid {
  display: grid;
  grid-gap: 0;
}
.app-calendar .app-sidebar .repeat-option-set .repeat-option-set-section__grid .repeat-option-set-section-grid-item,
.event-popover .event-popover__inner .repeat-option-set .repeat-option-set-section__grid .repeat-option-set-section-grid-item {
  padding: 5px;
  width: 100%;
  border: 1px solid var(--color-border-dark);
  text-align: center;
  margin: 0;
  border-radius: 0;
}
.app-calendar .app-sidebar .repeat-option-set--weekly .repeat-option-set-section__grid, .app-calendar .app-sidebar .repeat-option-set--monthly .repeat-option-set-section__grid,
.event-popover .event-popover__inner .repeat-option-set--weekly .repeat-option-set-section__grid,
.event-popover .event-popover__inner .repeat-option-set--monthly .repeat-option-set-section__grid {
  grid-template-columns: repeat(7, auto);
}
.app-calendar .app-sidebar .repeat-option-set--yearly .repeat-option-set-section__grid,
.event-popover .event-popover__inner .repeat-option-set--yearly .repeat-option-set-section__grid {
  grid-template-columns: repeat(4, auto);
}
.app-calendar .app-sidebar .repeat-option-set--interval-freq,
.event-popover .event-popover__inner .repeat-option-set--interval-freq {
  display: flex;
}
.app-calendar .app-sidebar .repeat-option-set--interval-freq .multiselect,
.event-popover .event-popover__inner .repeat-option-set--interval-freq .multiselect {
  min-width: 100px;
  width: 25%;
}
.app-calendar .app-sidebar .repeat-option-set--end,
.event-popover .event-popover__inner .repeat-option-set--end {
  margin-top: 10px;
}
.app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__label, .app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__end-type-select, .app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__until, .app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__count,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__label,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__end-type-select,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__until,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__count {
  flex: 1 1 auto;
  min-width: 0;
  margin-right: 10px;
}
.app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__label,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__label {
  display: block;
  min-width: 60px;
}
.app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__end-type-select,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__end-type-select {
  min-width: 90px;
  width: 100%;
}
.app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__until,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__until {
  min-width: 90px;
  width: 100%;
  display: inline-block;
}
.app-calendar .app-sidebar .repeat-option-set--end .repeat-option-end__count,
.event-popover .event-popover__inner .repeat-option-set--end .repeat-option-end__count {
  min-width: 90px;
  width: 100%;
}
.app-calendar .app-sidebar .repeat-option-set .end-repeat-container .end-repeat-dropdown,
.app-calendar .app-sidebar .repeat-option-set .end-repeat-container .end-repeat-date,
.event-popover .event-popover__inner .repeat-option-set .end-repeat-container .end-repeat-dropdown,
.event-popover .event-popover__inner .repeat-option-set .end-repeat-container .end-repeat-date {
  flex: 0 1 auto;
  min-width: 150px;
  width: auto;
}
.app-calendar .app-sidebar .repeat-option-set__label,
.event-popover .event-popover__inner .repeat-option-set__label {
  margin-right: auto;
}
.app-calendar .app-sidebar .repeat-option-warning,
.event-popover .event-popover__inner .repeat-option-warning {
  text-align: center;
}
.app-calendar .app-sidebar .property-title-time-picker,
.event-popover .event-popover__inner .property-title-time-picker {
  width: 100%;
}
.app-calendar .app-sidebar .property-title-time-picker--readonly,
.event-popover .event-popover__inner .property-title-time-picker--readonly {
  display: flex;
  align-items: flex-start;
}
.app-calendar .app-sidebar .property-title-time-picker--readonly > *:not([align-self=center]),
.event-popover .event-popover__inner .property-title-time-picker--readonly > *:not([align-self=center]) {
  align-self: flex-start !important;
}
.app-calendar .app-sidebar .property-title-time-picker__icon,
.event-popover .event-popover__inner .property-title-time-picker__icon {
  width: 34px;
  height: 34px;
  margin-left: -5px;
  margin-right: 5px;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers, .app-calendar .app-sidebar .property-title-time-picker__all-day,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers,
.event-popover .event-popover__inner .property-title-time-picker__all-day {
  display: flex;
  align-items: center;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers .mx-datepicker,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers .mx-datepicker {
  flex: 1 auto;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers .mx-datepicker .mx-input-append,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers .mx-datepicker .mx-input-append {
  background-color: transparent !important;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly {
  justify-content: start;
  margin-top: var(--default-grid-baseline);
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper {
  display: flex;
  align-items: center;
  padding-left: 8px !important;
  background-color: var(--color-main-background);
  color: var(--color-main-text);
  outline: none;
  gap: var(--default-grid-baseline);
  margin-bottom: var(--default-grid-baseline);
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper--start-date,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper--start-date {
  padding: 0;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper--end-date,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper--end-date {
  padding: 0;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon {
  margin-left: 8px;
  height: 16px;
  width: 16px;
  opacity: 0.3;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon--highlighted,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon--highlighted {
  opacity: 0.7;
}
.app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon:focus, .app-calendar .app-sidebar .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon:hover,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon:focus,
.event-popover .event-popover__inner .property-title-time-picker__time-pickers--readonly .property-title-time-picker-read-only-wrapper__icon:hover {
  opacity: 1;
}
.app-calendar .app-sidebar .property-title-time-picker__all-day,
.event-popover .event-popover__inner .property-title-time-picker__all-day {
  padding-left: 3px;
  margin-top: 5px;
}
.app-calendar .app-sidebar .property-title-time-picker__all-day .checkbox-radio-switch__label,
.event-popover .event-popover__inner .property-title-time-picker__all-day .checkbox-radio-switch__label {
  min-height: 32px;
}
.app-calendar .app-sidebar .property-title-time-picker .datetime-picker-inline-icon,
.event-popover .event-popover__inner .property-title-time-picker .datetime-picker-inline-icon {
  margin-top: 17px;
  opacity: 0.3;
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 6px !important;
}
.app-calendar .app-sidebar .property-title-time-picker .datetime-picker-inline-icon--highlighted,
.event-popover .event-popover__inner .property-title-time-picker .datetime-picker-inline-icon--highlighted {
  opacity: 0.7;
}
.app-calendar .app-sidebar .property-title-time-picker .datetime-picker-inline-icon:focus, .app-calendar .app-sidebar .property-title-time-picker .datetime-picker-inline-icon:hover,
.event-popover .event-popover__inner .property-title-time-picker .datetime-picker-inline-icon:focus,
.event-popover .event-popover__inner .property-title-time-picker .datetime-picker-inline-icon:hover {
  opacity: 1;
}
.app-calendar .app-sidebar .property-alarm-list,
.event-popover .event-popover__inner .property-alarm-list {
  width: 100%;
}
.app-calendar .app-sidebar .property-alarm-item,
.event-popover .event-popover__inner .property-alarm-item {
  display: flex;
  align-items: center;
  min-height: 44px;
}
.app-calendar .app-sidebar .property-alarm-item .v-select,
.event-popover .event-popover__inner .property-alarm-item .v-select {
  margin: 0;
}
.app-calendar .app-sidebar .property-alarm-item .vs__dropdown-toggle,
.event-popover .event-popover__inner .property-alarm-item .vs__dropdown-toggle {
  width: 200px;
}
.app-calendar .app-sidebar .property-alarm-item__icon,
.event-popover .event-popover__inner .property-alarm-item__icon {
  align-self: flex-start;
}
.app-calendar .app-sidebar .property-alarm-item__icon--hidden,
.event-popover .event-popover__inner .property-alarm-item__icon--hidden {
  visibility: hidden;
}
.app-calendar .app-sidebar .property-alarm-item__icon .icon,
.event-popover .event-popover__inner .property-alarm-item__icon .icon {
  width: 34px;
  height: 44px;
  margin-left: -5px;
  margin-right: 5px;
}
.app-calendar .app-sidebar .property-alarm-item__label,
.event-popover .event-popover__inner .property-alarm-item__label {
  padding: 0 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
}
.app-calendar .app-sidebar .property-alarm-item__options,
.event-popover .event-popover__inner .property-alarm-item__options {
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.app-calendar .app-sidebar .property-alarm-item__edit,
.event-popover .event-popover__inner .property-alarm-item__edit {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  padding-right: 8px;
}
.app-calendar .app-sidebar .property-alarm-item__edit input[type=number],
.event-popover .event-popover__inner .property-alarm-item__edit input[type=number] {
  width: 4em;
}
.app-calendar .app-sidebar .property-alarm-item__edit .multiselect,
.event-popover .event-popover__inner .property-alarm-item__edit .multiselect {
  flex: 1 auto;
  height: 34px;
  min-width: 0;
}
.app-calendar .app-sidebar .property-alarm-item__edit .mx-datepicker,
.event-popover .event-popover__inner .property-alarm-item__edit .mx-datepicker {
  flex: 1 auto;
}
.app-calendar .app-sidebar .property-alarm-item__edit--all-day,
.event-popover .event-popover__inner .property-alarm-item__edit--all-day {
  flex-wrap: wrap;
  margin-bottom: 5px;
  gap: 0 5px;
}
.app-calendar .app-sidebar .property-alarm-item__edit--all-day__distance, .app-calendar .app-sidebar .property-alarm-item__edit--all-day__time,
.event-popover .event-popover__inner .property-alarm-item__edit--all-day__distance,
.event-popover .event-popover__inner .property-alarm-item__edit--all-day__time {
  display: flex;
  flex: 1;
  align-items: center;
}
.app-calendar .app-sidebar .property-alarm-item__edit--all-day__distance .multiselect,
.event-popover .event-popover__inner .property-alarm-item__edit--all-day__distance .multiselect {
  width: 6em;
}
.app-calendar .app-sidebar .property-alarm-item__edit--all-day__time__before-at-label,
.event-popover .event-popover__inner .property-alarm-item__edit--all-day__time__before-at-label {
  flex: 0 0 auto;
  margin-right: 5px;
}
.app-calendar .app-sidebar .property-alarm-item__edit--all-day__time .mx-datepicker,
.event-popover .event-popover__inner .property-alarm-item__edit--all-day__time .mx-datepicker {
  width: 7em;
}
.app-calendar .app-sidebar .property-alarm-item__edit--absolute .mx-datepicker,
.event-popover .event-popover__inner .property-alarm-item__edit--absolute .mx-datepicker {
  width: unset;
}
.app-calendar .app-sidebar .property-repeat,
.event-popover .event-popover__inner .property-repeat {
  width: 100%;
}
.app-calendar .app-sidebar .property-repeat__summary,
.event-popover .event-popover__inner .property-repeat__summary {
  display: flex;
  align-items: center;
}
.app-calendar .app-sidebar .property-repeat__summary__icon,
.event-popover .event-popover__inner .property-repeat__summary__icon {
  width: 34px;
  height: 34px;
  margin-left: -5px;
  margin-right: 5px;
}
.app-calendar .app-sidebar .property-repeat__summary__content,
.event-popover .event-popover__inner .property-repeat__summary__content {
  flex: 1 auto;
  padding: 8px 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-calendar .app-sidebar .property-repeat__options,
.event-popover .event-popover__inner .property-repeat__options {
  margin-bottom: 5px;
}
.app-calendar .app-sidebar .resource-list-item,
.app-calendar .app-sidebar .invitees-list-item,
.event-popover .event-popover__inner .resource-list-item,
.event-popover .event-popover__inner .invitees-list-item {
  display: flex;
  align-items: center;
  min-height: 44px;
}
.app-calendar .app-sidebar .resource-list-item__displayname,
.app-calendar .app-sidebar .invitees-list-item__displayname,
.event-popover .event-popover__inner .resource-list-item__displayname,
.event-popover .event-popover__inner .invitees-list-item__displayname {
  margin-left: 8px;
}
.app-calendar .app-sidebar .resource-list-item__actions,
.app-calendar .app-sidebar .invitees-list-item__actions,
.event-popover .event-popover__inner .resource-list-item__actions,
.event-popover .event-popover__inner .invitees-list-item__actions {
  margin-left: auto;
}
.app-calendar .app-sidebar .resource-list-item__organizer-hint,
.app-calendar .app-sidebar .invitees-list-item__organizer-hint,
.event-popover .event-popover__inner .resource-list-item__organizer-hint,
.event-popover .event-popover__inner .invitees-list-item__organizer-hint {
  color: var(--color-text-maxcontrast);
  font-weight: 300;
  margin-left: 5px;
}
.app-calendar .app-sidebar .resource-search__capacity,
.event-popover .event-popover__inner .resource-search__capacity {
  display: flex;
  align-items: center;
}
.app-calendar .app-sidebar .resource-search__capacity__actions,
.event-popover .event-popover__inner .resource-search__capacity__actions {
  margin-left: 5px;
}
.app-calendar .app-sidebar .avatar-participation-status,
.event-popover .event-popover__inner .avatar-participation-status {
  position: relative;
  height: 38px;
  width: 38px;
}
.app-calendar .app-sidebar .avatar-participation-status__indicator,
.event-popover .event-popover__inner .avatar-participation-status__indicator {
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: 10px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.app-calendar .app-sidebar .avatar-participation-status__indicator.accepted,
.event-popover .event-popover__inner .avatar-participation-status__indicator.accepted {
  background-color: #2fb130;
}
.app-calendar .app-sidebar .avatar-participation-status__indicator.declined,
.event-popover .event-popover__inner .avatar-participation-status__indicator.declined {
  background-color: #ff0000;
}
.app-calendar .app-sidebar .avatar-participation-status__indicator.tentative,
.event-popover .event-popover__inner .avatar-participation-status__indicator.tentative {
  background-color: #ffa704;
}
.app-calendar .app-sidebar .avatar-participation-status__indicator.delegated, .app-calendar .app-sidebar .avatar-participation-status__indicator.no-response,
.event-popover .event-popover__inner .avatar-participation-status__indicator.delegated,
.event-popover .event-popover__inner .avatar-participation-status__indicator.no-response {
  background-color: grey;
}
.app-calendar .app-sidebar .property-text,
.app-calendar .app-sidebar .property-select,
.app-calendar .app-sidebar .property-color,
.app-calendar .app-sidebar .property-select-multiple,
.app-calendar .app-sidebar .property-title,
.app-calendar .app-sidebar .resource-capacity,
.app-calendar .app-sidebar .resource-room-type,
.event-popover .event-popover__inner .property-text,
.event-popover .event-popover__inner .property-select,
.event-popover .event-popover__inner .property-color,
.event-popover .event-popover__inner .property-select-multiple,
.event-popover .event-popover__inner .property-title,
.event-popover .event-popover__inner .resource-capacity,
.event-popover .event-popover__inner .resource-room-type {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.app-calendar .app-sidebar .property-text__icon, .app-calendar .app-sidebar .property-text__info,
.app-calendar .app-sidebar .property-select__icon,
.app-calendar .app-sidebar .property-select__info,
.app-calendar .app-sidebar .property-color__icon,
.app-calendar .app-sidebar .property-color__info,
.app-calendar .app-sidebar .property-select-multiple__icon,
.app-calendar .app-sidebar .property-select-multiple__info,
.app-calendar .app-sidebar .property-title__icon,
.app-calendar .app-sidebar .property-title__info,
.app-calendar .app-sidebar .resource-capacity__icon,
.app-calendar .app-sidebar .resource-capacity__info,
.app-calendar .app-sidebar .resource-room-type__icon,
.app-calendar .app-sidebar .resource-room-type__info,
.event-popover .event-popover__inner .property-text__icon,
.event-popover .event-popover__inner .property-text__info,
.event-popover .event-popover__inner .property-select__icon,
.event-popover .event-popover__inner .property-select__info,
.event-popover .event-popover__inner .property-color__icon,
.event-popover .event-popover__inner .property-color__info,
.event-popover .event-popover__inner .property-select-multiple__icon,
.event-popover .event-popover__inner .property-select-multiple__info,
.event-popover .event-popover__inner .property-title__icon,
.event-popover .event-popover__inner .property-title__info,
.event-popover .event-popover__inner .resource-capacity__icon,
.event-popover .event-popover__inner .resource-capacity__info,
.event-popover .event-popover__inner .resource-room-type__icon,
.event-popover .event-popover__inner .resource-room-type__info {
  height: 34px;
  width: 34px;
}
.app-calendar .app-sidebar .property-text__icon--hidden,
.app-calendar .app-sidebar .property-select__icon--hidden,
.app-calendar .app-sidebar .property-color__icon--hidden,
.app-calendar .app-sidebar .property-select-multiple__icon--hidden,
.app-calendar .app-sidebar .property-title__icon--hidden,
.app-calendar .app-sidebar .resource-capacity__icon--hidden,
.app-calendar .app-sidebar .resource-room-type__icon--hidden,
.event-popover .event-popover__inner .property-text__icon--hidden,
.event-popover .event-popover__inner .property-select__icon--hidden,
.event-popover .event-popover__inner .property-color__icon--hidden,
.event-popover .event-popover__inner .property-select-multiple__icon--hidden,
.event-popover .event-popover__inner .property-title__icon--hidden,
.event-popover .event-popover__inner .resource-capacity__icon--hidden,
.event-popover .event-popover__inner .resource-room-type__icon--hidden {
  visibility: hidden;
}
.app-calendar .app-sidebar .property-text__info,
.app-calendar .app-sidebar .property-select__info,
.app-calendar .app-sidebar .property-color__info,
.app-calendar .app-sidebar .property-select-multiple__info,
.app-calendar .app-sidebar .property-title__info,
.app-calendar .app-sidebar .resource-capacity__info,
.app-calendar .app-sidebar .resource-room-type__info,
.event-popover .event-popover__inner .property-text__info,
.event-popover .event-popover__inner .property-select__info,
.event-popover .event-popover__inner .property-color__info,
.event-popover .event-popover__inner .property-select-multiple__info,
.event-popover .event-popover__inner .property-title__info,
.event-popover .event-popover__inner .resource-capacity__info,
.event-popover .event-popover__inner .resource-room-type__info {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  opacity: 0.5;
}
.app-calendar .app-sidebar .property-text__info:hover,
.app-calendar .app-sidebar .property-select__info:hover,
.app-calendar .app-sidebar .property-color__info:hover,
.app-calendar .app-sidebar .property-select-multiple__info:hover,
.app-calendar .app-sidebar .property-title__info:hover,
.app-calendar .app-sidebar .resource-capacity__info:hover,
.app-calendar .app-sidebar .resource-room-type__info:hover,
.event-popover .event-popover__inner .property-text__info:hover,
.event-popover .event-popover__inner .property-select__info:hover,
.event-popover .event-popover__inner .property-color__info:hover,
.event-popover .event-popover__inner .property-select-multiple__info:hover,
.event-popover .event-popover__inner .property-title__info:hover,
.event-popover .event-popover__inner .resource-capacity__info:hover,
.event-popover .event-popover__inner .resource-room-type__info:hover {
  opacity: 1;
}
.app-calendar .app-sidebar .property-text__icon,
.app-calendar .app-sidebar .property-select__icon,
.app-calendar .app-sidebar .property-color__icon,
.app-calendar .app-sidebar .property-select-multiple__icon,
.app-calendar .app-sidebar .property-title__icon,
.app-calendar .app-sidebar .resource-capacity__icon,
.app-calendar .app-sidebar .resource-room-type__icon,
.event-popover .event-popover__inner .property-text__icon,
.event-popover .event-popover__inner .property-select__icon,
.event-popover .event-popover__inner .property-color__icon,
.event-popover .event-popover__inner .property-select-multiple__icon,
.event-popover .event-popover__inner .property-title__icon,
.event-popover .event-popover__inner .resource-capacity__icon,
.event-popover .event-popover__inner .resource-room-type__icon {
  flex-shrink: 0;
  margin-left: -5px;
  margin-right: 5px;
}
.app-calendar .app-sidebar .property-text__input,
.app-calendar .app-sidebar .property-select__input,
.app-calendar .app-sidebar .property-color__input,
.app-calendar .app-sidebar .property-select-multiple__input,
.app-calendar .app-sidebar .property-title__input,
.app-calendar .app-sidebar .resource-capacity__input,
.app-calendar .app-sidebar .resource-room-type__input,
.event-popover .event-popover__inner .property-text__input,
.event-popover .event-popover__inner .property-select__input,
.event-popover .event-popover__inner .property-color__input,
.event-popover .event-popover__inner .property-select-multiple__input,
.event-popover .event-popover__inner .property-title__input,
.event-popover .event-popover__inner .resource-capacity__input,
.event-popover .event-popover__inner .resource-room-type__input {
  flex-grow: 2;
}
.app-calendar .app-sidebar .property-text__input textarea,
.app-calendar .app-sidebar .property-text__input input,
.app-calendar .app-sidebar .property-text__input div.v-select,
.app-calendar .app-sidebar .property-select__input textarea,
.app-calendar .app-sidebar .property-select__input input,
.app-calendar .app-sidebar .property-select__input div.v-select,
.app-calendar .app-sidebar .property-color__input textarea,
.app-calendar .app-sidebar .property-color__input input,
.app-calendar .app-sidebar .property-color__input div.v-select,
.app-calendar .app-sidebar .property-select-multiple__input textarea,
.app-calendar .app-sidebar .property-select-multiple__input input,
.app-calendar .app-sidebar .property-select-multiple__input div.v-select,
.app-calendar .app-sidebar .property-title__input textarea,
.app-calendar .app-sidebar .property-title__input input,
.app-calendar .app-sidebar .property-title__input div.v-select,
.app-calendar .app-sidebar .resource-capacity__input textarea,
.app-calendar .app-sidebar .resource-capacity__input input,
.app-calendar .app-sidebar .resource-capacity__input div.v-select,
.app-calendar .app-sidebar .resource-room-type__input textarea,
.app-calendar .app-sidebar .resource-room-type__input input,
.app-calendar .app-sidebar .resource-room-type__input div.v-select,
.event-popover .event-popover__inner .property-text__input textarea,
.event-popover .event-popover__inner .property-text__input input,
.event-popover .event-popover__inner .property-text__input div.v-select,
.event-popover .event-popover__inner .property-select__input textarea,
.event-popover .event-popover__inner .property-select__input input,
.event-popover .event-popover__inner .property-select__input div.v-select,
.event-popover .event-popover__inner .property-color__input textarea,
.event-popover .event-popover__inner .property-color__input input,
.event-popover .event-popover__inner .property-color__input div.v-select,
.event-popover .event-popover__inner .property-select-multiple__input textarea,
.event-popover .event-popover__inner .property-select-multiple__input input,
.event-popover .event-popover__inner .property-select-multiple__input div.v-select,
.event-popover .event-popover__inner .property-title__input textarea,
.event-popover .event-popover__inner .property-title__input input,
.event-popover .event-popover__inner .property-title__input div.v-select,
.event-popover .event-popover__inner .resource-capacity__input textarea,
.event-popover .event-popover__inner .resource-capacity__input input,
.event-popover .event-popover__inner .resource-capacity__input div.v-select,
.event-popover .event-popover__inner .resource-room-type__input textarea,
.event-popover .event-popover__inner .resource-room-type__input input,
.event-popover .event-popover__inner .resource-room-type__input div.v-select {
  width: 100%;
}
.app-calendar .app-sidebar .property-text__input textarea,
.app-calendar .app-sidebar .property-select__input textarea,
.app-calendar .app-sidebar .property-color__input textarea,
.app-calendar .app-sidebar .property-select-multiple__input textarea,
.app-calendar .app-sidebar .property-title__input textarea,
.app-calendar .app-sidebar .resource-capacity__input textarea,
.app-calendar .app-sidebar .resource-room-type__input textarea,
.event-popover .event-popover__inner .property-text__input textarea,
.event-popover .event-popover__inner .property-select__input textarea,
.event-popover .event-popover__inner .property-color__input textarea,
.event-popover .event-popover__inner .property-select-multiple__input textarea,
.event-popover .event-popover__inner .property-title__input textarea,
.event-popover .event-popover__inner .resource-capacity__input textarea,
.event-popover .event-popover__inner .resource-room-type__input textarea {
  max-height: calc(100vh - 500px);
  vertical-align: top;
  margin: 0;
}
.app-calendar .app-sidebar .property-text__input--readonly div,
.app-calendar .app-sidebar .property-select__input--readonly div,
.app-calendar .app-sidebar .property-color__input--readonly div,
.app-calendar .app-sidebar .property-select-multiple__input--readonly div,
.app-calendar .app-sidebar .property-title__input--readonly div,
.app-calendar .app-sidebar .resource-capacity__input--readonly div,
.app-calendar .app-sidebar .resource-room-type__input--readonly div,
.event-popover .event-popover__inner .property-text__input--readonly div,
.event-popover .event-popover__inner .property-select__input--readonly div,
.event-popover .event-popover__inner .property-color__input--readonly div,
.event-popover .event-popover__inner .property-select-multiple__input--readonly div,
.event-popover .event-popover__inner .property-title__input--readonly div,
.event-popover .event-popover__inner .resource-capacity__input--readonly div,
.event-popover .event-popover__inner .resource-room-type__input--readonly div {
  width: calc(100% - 8px); /* for typical (thin) scrollbar size */
  white-space: pre-line;
  padding: 8px 7px;
  background-color: var(--color-main-background);
  color: var(--color-main-text);
  outline: none;
  overflow-y: scroll;
  word-break: break-word; /* allows breaking on long URLs */
  max-height: 30vh;
}
.app-calendar .app-sidebar .property-text__input--readonly-calendar-picker div.calendar-picker-option,
.app-calendar .app-sidebar .property-select__input--readonly-calendar-picker div.calendar-picker-option,
.app-calendar .app-sidebar .property-color__input--readonly-calendar-picker div.calendar-picker-option,
.app-calendar .app-sidebar .property-select-multiple__input--readonly-calendar-picker div.calendar-picker-option,
.app-calendar .app-sidebar .property-title__input--readonly-calendar-picker div.calendar-picker-option,
.app-calendar .app-sidebar .resource-capacity__input--readonly-calendar-picker div.calendar-picker-option,
.app-calendar .app-sidebar .resource-room-type__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .property-text__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .property-select__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .property-color__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .property-select-multiple__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .property-title__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .resource-capacity__input--readonly-calendar-picker div.calendar-picker-option,
.event-popover .event-popover__inner .resource-room-type__input--readonly-calendar-picker div.calendar-picker-option {
  padding: 8px 7px;
}
.app-calendar .app-sidebar .property-text,
.app-calendar .app-sidebar .property-select,
.app-calendar .app-sidebar .property-color,
.app-calendar .app-sidebar .property-select-multiple,
.app-calendar .app-sidebar .property-title,
.app-calendar .app-sidebar .property-repeat,
.app-calendar .app-sidebar .resource-capacity,
.app-calendar .app-sidebar .resource-room-type,
.event-popover .event-popover__inner .property-text,
.event-popover .event-popover__inner .property-select,
.event-popover .event-popover__inner .property-color,
.event-popover .event-popover__inner .property-select-multiple,
.event-popover .event-popover__inner .property-title,
.event-popover .event-popover__inner .property-repeat,
.event-popover .event-popover__inner .resource-capacity,
.event-popover .event-popover__inner .resource-room-type {
  margin-bottom: 5px;
}
.app-calendar .app-sidebar .property-text--readonly,
.app-calendar .app-sidebar .property-select--readonly,
.app-calendar .app-sidebar .property-color--readonly,
.app-calendar .app-sidebar .property-select-multiple--readonly,
.app-calendar .app-sidebar .property-title--readonly,
.app-calendar .app-sidebar .property-repeat--readonly,
.app-calendar .app-sidebar .resource-capacity--readonly,
.app-calendar .app-sidebar .resource-room-type--readonly,
.event-popover .event-popover__inner .property-text--readonly,
.event-popover .event-popover__inner .property-select--readonly,
.event-popover .event-popover__inner .property-color--readonly,
.event-popover .event-popover__inner .property-select-multiple--readonly,
.event-popover .event-popover__inner .property-title--readonly,
.event-popover .event-popover__inner .property-repeat--readonly,
.event-popover .event-popover__inner .resource-capacity--readonly,
.event-popover .event-popover__inner .resource-room-type--readonly {
  margin-bottom: 0;
  align-content: center;
}
.app-calendar .app-sidebar .property-text--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .property-select--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .property-color--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .property-select-multiple--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .property-title--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .property-repeat--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .resource-capacity--readonly > *:not([align-self=flex-start]),
.app-calendar .app-sidebar .resource-room-type--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .property-text--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .property-select--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .property-color--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .property-select-multiple--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .property-title--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .property-repeat--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .resource-capacity--readonly > *:not([align-self=flex-start]),
.event-popover .event-popover__inner .resource-room-type--readonly > *:not([align-self=flex-start]) {
  align-self: center !important;
}
.app-calendar .app-sidebar .property-select,
.app-calendar .app-sidebar .property-select-multiple,
.event-popover .event-popover__inner .property-select,
.event-popover .event-popover__inner .property-select-multiple {
  align-items: center;
}
.app-calendar .app-sidebar .property-select .v-select,
.app-calendar .app-sidebar .property-select-multiple .v-select,
.event-popover .event-popover__inner .property-select .v-select,
.event-popover .event-popover__inner .property-select-multiple .v-select {
  min-width: unset !important;
}
.app-calendar .app-sidebar .property-color__input,
.event-popover .event-popover__inner .property-color__input {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}
.app-calendar .app-sidebar .property-color__input--readonly,
.event-popover .event-popover__inner .property-color__input--readonly {
  margin: 3px 0 3px 7px;
}
.app-calendar .app-sidebar .property-color__color-preview,
.event-popover .event-popover__inner .property-color__color-preview {
  width: var(--default-clickable-area) !important;
  height: var(--default-clickable-area) !important;
  border-radius: var(--default-clickable-area);
}
.app-calendar .app-sidebar .property-text__icon,
.event-popover .event-popover__inner .property-text__icon {
  height: unset;
  align-self: flex-start;
  padding-top: 12px;
}
.app-calendar .app-sidebar .property-text--readonly .property-text__icon,
.event-popover .event-popover__inner .property-text--readonly .property-text__icon {
  padding-top: 0;
}
.app-calendar .app-sidebar .property-text__input--readonly,
.event-popover .event-popover__inner .property-text__input--readonly {
  line-height: 1;
  padding-top: calc(var(--default-line-height) / 2 - 0.5lh);
}
.app-calendar .app-sidebar .property-text__input textarea,
.event-popover .event-popover__inner .property-text__input textarea {
  resize: none;
}
.app-calendar .app-sidebar .property-select-multiple .property-select-multiple__input.property-select-multiple__input--readonly,
.event-popover .event-popover__inner .property-select-multiple .property-select-multiple__input.property-select-multiple__input--readonly {
  width: 100%;
}
.app-calendar .app-sidebar .property-select-multiple .property-select-multiple__input.property-select-multiple__input--readonly .property-select-multiple-colored-tag-wrapper,
.event-popover .event-popover__inner .property-select-multiple .property-select-multiple__input.property-select-multiple__input--readonly .property-select-multiple-colored-tag-wrapper {
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  padding: 3px 5px;
}
.app-calendar .app-sidebar .property-select-multiple .property-select-multiple__input.property-select-multiple__input--readonly .property-select-multiple-colored-tag-wrapper .multiselect__tag,
.event-popover .event-popover__inner .property-select-multiple .property-select-multiple__input.property-select-multiple__input--readonly .property-select-multiple-colored-tag-wrapper .multiselect__tag {
  line-height: 20px;
  padding: 1px 5px;
  background-image: none;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  max-width: fit-content;
  margin: 3px;
}
.app-calendar .app-sidebar .property-title__input, .app-calendar .app-sidebar .property-title input,
.event-popover .event-popover__inner .property-title__input,
.event-popover .event-popover__inner .property-title input {
  font-weight: bold;
}
.app-calendar .app-sidebar .property-title__input__rtl, .app-calendar .app-sidebar .property-title input__rtl,
.event-popover .event-popover__inner .property-title__input__rtl,
.event-popover .event-popover__inner .property-title input__rtl {
  text-align: right;
}
.app-calendar .app-sidebar .property-title__input--readonly,
.event-popover .event-popover__inner .property-title__input--readonly {
  font-size: 18px;
}
.app-calendar .app-sidebar .property-title input,
.app-calendar .app-sidebar .property-title-time-picker input,
.event-popover .event-popover__inner .property-title input,
.event-popover .event-popover__inner .property-title-time-picker input {
  margin: 0;
}
.app-calendar .app-sidebar .resource-room-type,
.event-popover .event-popover__inner .resource-room-type {
  margin-bottom: 5px;
}

.event-popover .event-popover__inner .event-popover__response-buttons {
  margin-top: 8px;
  margin-bottom: 0;
}
.event-popover .event-popover__inner .property-text__icon,
.event-popover .event-popover__inner .property-title-time-picker__icon {
  margin: 0 !important;
}

.timezone-popover-wrapper .popover__inner {
  padding: 20px;
}
.timezone-popover-wrapper__title {
  margin-bottom: 8px;
}
.timezone-popover-wrapper__timezone-select {
  min-width: 200px;
}

.event-popover .v-popper__inner {
  overflow: unset !important;
}
.event-popover .event-popover__inner {
  text-align: left;
  max-width: 480px;
  width: 480px;
  padding: 5px 10px 10px 10px;
}
.event-popover .event-popover__inner .empty-content {
  margin-top: 0 !important;
  padding: 50px 0;
}
.event-popover .event-popover__inner .property-title-time-picker:not(.property-title-time-picker--readonly) {
  margin-bottom: 12px;
}
.event-popover .event-popover__inner .event-popover__invitees .avatar-participation-status__text {
  bottom: 22px;
}
.event-popover .event-popover__inner .event-popover__buttons {
  margin-top: 8px;
}
.event-popover .event-popover__inner .event-popover__top-actions {
  display: flex;
  gap: var(--default-grid-baseline);
  position: absolute !important;
  top: var(--default-grid-baseline) !important;
  z-index: 100 !important;
  opacity: 0.7 !important;
  border-radius: 22px !important;
  align-items: center;
}
.event-popover .event-popover__inner .event-popover__top-actions--right {
  right: var(--default-grid-baseline) !important;
}
.event-popover .event-popover__inner .event-popover__top-actions--left {
  left: var(--default-grid-baseline) !important;
}
.event-popover .event-popover__inner .event-popover__top-actions .action-item.action-item--single {
  width: 44px !important;
  height: 44px !important;
}
.event-popover .event-popover__inner .popover-loading-indicator {
  width: 100%;
}
.event-popover .event-popover__inner .popover-loading-indicator__icon {
  margin: 0 auto;
  height: 62px;
  width: 62px;
  background-size: 62px;
}
.event-popover[x-out-of-boundaries] {
  margin-top: 75px;
}

.event-popover[x-placement^=bottom] .popover__arrow {
  border-bottom-color: var(--color-background-dark);
}

.calendar-picker-option {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.calendar-picker-option__color-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  margin-right: 8px;
  flex-basis: 12px;
  flex-shrink: 0;
}
.calendar-picker-option__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}
.calendar-picker-option__avatar {
  flex-basis: 18px;
  flex-shrink: 0;
}

.property-select-multiple-colored-tag {
  width: 100%;
  display: flex;
  align-items: center;
}
.property-select-multiple-colored-tag__color-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  margin-right: 8px;
  flex-shrink: 0;
}
.property-select-multiple-colored-tag .icon {
  margin-left: 4px;
  scale: 0.8;
}

.resource-list-button-group,
.invitees-list-button-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resource-list-button-group:not(:empty),
.invitees-list-button-group:not(:empty) {
  margin-top: 20px;
}

.vs__dropdown-option span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resource-search-list-item,
.invitees-search-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: start;
  padding-inline-end: 32px;
}
.resource-search-list-item__label,
.invitees-search-list-item__label {
  width: 100%;
  padding: 0 8px;
}
.resource-search-list-item__label__availability,
.invitees-search-list-item__label__availability {
  color: var(--color-text-maxcontrast);
}
.resource-search-list-item__label div,
.invitees-search-list-item__label div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.resource-search-list-item__label div:nth-child(1),
.invitees-search-list-item__label div:nth-child(1) {
  color: var(--color-main-text);
}
.resource-search-list-item__label div:nth-child(2),
.invitees-search-list-item__label div:nth-child(2) {
  color: var(--color-text-lighter);
  line-height: 1;
}

.resource-search__multiselect,
.invitees-search__multiselect {
  width: 100%;
}

/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
#app-settings .settings-fieldset-interior-item {
  padding: 5px 0;
}
#app-settings .settings-fieldset-interior-item .action-checkbox {
  line-height: unset !important;
  white-space: unset !important;
}
#app-settings .settings-fieldset-interior-item .action-checkbox__label::before {
  margin: 0 6px 3px 3px !important;
  flex-shrink: 0;
}
#app-settings .settings-fieldset-interior-item .action-button {
  min-height: unset !important;
}
#app-settings .settings-fieldset-interior-item .action-button__icon {
  margin: 0 6px 3px 3px !important;
  height: 14px !important;
  width: 14px !important;
  background-position: unset !important;
}
#app-settings .settings-fieldset-interior-item .action-button__longtext {
  width: unset !important;
  padding: 0 !important;
}
#app-settings .settings-fieldset-interior-item--slotDuration, #app-settings .settings-fieldset-interior-item--defaultReminder {
  display: table;
}
#app-settings .settings-fieldset-interior-item--slotDuration label, #app-settings .settings-fieldset-interior-item--defaultReminder label {
  display: block;
}
#app-settings .settings-fieldset-interior-item--slotDuration .multiselect, #app-settings .settings-fieldset-interior-item--defaultReminder .multiselect {
  display: block;
}
#app-settings .settings-fieldset-interior-item--timezone, #app-settings .settings-fieldset-interior-item--default-calendar {
  width: 100%;
}
#app-settings .settings-fieldset-interior-item--timezone .multiselect, #app-settings .settings-fieldset-interior-item--default-calendar .multiselect {
  width: 100%;
}

.shortcut-overview-modal .modal-container {
  display: flex !important;
  flex-wrap: wrap;
  padding: 0 12px 12px 12px !important;
}
.shortcut-overview-modal .modal-container * {
  box-sizing: border-box;
}
.shortcut-overview-modal .modal-container .shortcut-section {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
}
.shortcut-overview-modal .modal-container .shortcut-section .shortcut-section-item {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 67%;
  column-gap: 10px;
}
.shortcut-overview-modal .modal-container .shortcut-section .shortcut-section-item:not(:first-child) {
  margin-top: 10px;
}
.shortcut-overview-modal .modal-container .shortcut-section .shortcut-section-item__keys {
  display: block;
  text-align: right;
}
.shortcut-overview-modal .modal-container .shortcut-section .shortcut-section-item__label {
  display: block;
  text-align: left;
  padding-top: 5px;
}
.shortcut-overview-modal .modal-container .shortcut-section .shortcut-section-item__spacer {
  margin: 0 3px;
}

@media screen and (max-width: 800px) {
  .shortcut-overview-modal .modal-container .shortcut-section {
    width: 100%;
  }
}
/*!
 * SPDX-FileCopyrightText: 2021 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.appointment-config-modal {
  padding: 2vw;
}
.appointment-config-modal__form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.appointment-config-modal__form fieldset {
  padding: 20px 0;
}
.appointment-config-modal__form fieldset header {
  font-size: 16px;
  margin-bottom: 3px;
}
.appointment-config-modal__form .availability-select, .appointment-config-modal__form .calendar-select {
  display: flex;
  flex-direction: column;
}
.appointment-config-modal__form__row--wrapped {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 50px;
}
.appointment-config-modal__form__row--wrapped > div {
  flex: 1 200px;
}
.appointment-config-modal__form__row--local {
  display: flex;
  flex-direction: column;
}
.appointment-config-modal__form__row + .appointment-config-modal__form__row {
  margin-top: 10px;
}
.appointment-config-modal__form .multiselect__tags {
  height: unset !important;
  margin: 0 !important;
}
.appointment-config-modal__submit-button {
  margin-top: 20px;
}

.app-config-modal-confirmation .empty-content {
  margin-top: 0 !important;
  margin-bottom: 20px;
}
.app-config-modal-confirmation__buttons {
  display: flex;
  justify-content: center;
  gap: 0 10px;
}

/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.modal--scheduler {
  position: relative;
}
.modal--scheduler .fc-bgevent {
  opacity: 0.8;
}
.modal--scheduler .blocking-event-free-busy {
  border-color: var(--color-primary-element);
  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  background-color: transparent !important;
  opacity: 0.7 !important;
  z-index: 2;
}
.modal--scheduler .blocking-event-free-busy.blocking-event-free-busy--first-row {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border-top-width: 2px;
}
.modal--scheduler .blocking-event-free-busy.blocking-event-free-busy--last-row {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-bottom-width: 2px;
}
.modal--scheduler .loading-indicator {
  width: 100%;
  position: absolute;
  top: 0;
  height: 50px;
  margin-top: 75px;
}

.freebusy-caption {
  margin-top: 10px;
}
.freebusy-caption__calendar-user-types, .freebusy-caption__colors {
  width: 50%;
  display: flex;
}
.freebusy-caption__colors {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.freebusy-caption__colors .freebusy-caption-item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.freebusy-caption__colors .freebusy-caption-item__color {
  height: 1em;
  width: 2em;
  display: block;
  border: 1px solid var(--color-border-dark);
  opacity: 0.8;
}
.freebusy-caption__colors .freebusy-caption-item__label {
  margin-left: 5px;
}

/*!
 * SPDX-FileCopyrightText: 2020 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
/** Override some FullCalendar CSS vars: */
.fc {
  --fc-small-font-size: 0.875em;
  --fc-page-bg-color: var(--color-main-background) !important;
  --fc-neutral-bg-color: var(--color-background-dark) !important;
  --fc-neutral-text-color: var(--color-text-lighter) !important;
  --fc-border-color: var(--color-border) !important;
  --fc-daygrid-event-dot-width: 10px !important;
  --fc-event-bg-color: var(--color-primary-element);
  --fc-event-border-color: var(--color-primary-element-text);
  --fc-event-text-color: var(--color-primary-element-text);
  --fc-event-selected-overlay-color: var(--color-box-shadow);
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: var(--color-background-dark);
  --fc-bg-event-color: var(--color-primary-element);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: var(--color-main-background) !important;
  --fc-now-indicator-color: red;
  --fc-list-event-hover-bg-color: var(--color-background-hover) !important;
}

.fc {
  font-family: var(--font-face) !important;
}

.fc-timegrid-axis-frame,
.fc-timegrid-slot-label,
.fc-col-header-cell a {
  color: var(--color-text-lighter) !important;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: none !important;
}

.fc-daygrid-day-top {
  justify-content: center;
}

.fc-state-highlight.fc-day-number,
.fc tbody tr,
.fc tbody tr:hover,
.fc tbody tr:focus {
  background: inherit !important;
}

.fc-day-today.fc-col-header-cell a, .fc-day-today.fc-col-header-cell span {
  padding: 2px 6px;
  font-weight: bold;
  background-color: var(--color-primary-element);
  color: var(--color-primary-element-text) !important;
  border-radius: var(--border-radius-small);
}
.fc-day-today .fc-event {
  box-shadow: 0px 0px 0px 1px var(--color-primary-element-light) !important;
}
.fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  margin: 4px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: bold !important;
  padding: 0 !important;
  background: var(--color-primary-element);
  color: var(--color-primary-element-text);
  border-radius: var(--border-radius-small);
}

.fc-list-table td {
  white-space: normal;
  word-break: break-word;
}

.fc .fc-list-sticky .fc-list-day > * {
  z-index: 1;
}

.fc-list-table .fc-list-day-cushion {
  padding-left: calc(var(--default-clickable-area) + var(--default-grid-baseline) * 2);
}

.fc-timeGridWeek-view .fc-col-header-cell.fc-day-today,
.fc-timeGridWeek-view .fc-daygrid-day.fc-day-today,
.fc-timeGridWeek-view .fc-timegrid-col.fc-day-today,
.fc-dayGridMonth-view .fc-col-header-cell.fc-day-today,
.fc-dayGridMonth-view .fc-daygrid-day.fc-day-today,
.fc-dayGridMonth-view .fc-timegrid-col.fc-day-today {
  background-color: var(--color-primary-element-light) !important;
}

.fc-daygrid-day.fc-day.fc-day-other,
.fc .fc-daygrid-day.fc-day-today.fc-day-other {
  background-color: var(--color-background-dark) !important;
  border: 1px solid var(--color-background-darker);
}
.fc-daygrid-day.fc-day.fc-day-other .fc-daygrid-day-top,
.fc .fc-daygrid-day.fc-day-today.fc-day-other .fc-daygrid-day-top {
  opacity: 0.6;
}

.fc-event {
  padding-left: 3px;
}
.fc-event.fc-event-nc-task-completed, .fc-event.fc-event-nc-tentative, .fc-event.fc-event-past, .fc-event.fc-event-nc-cancelled {
  opacity: 0.5;
}
.fc-event.fc-event-nc-task-completed .fc-event-title,
.fc-event.fc-event-nc-task-completed .fc-list-event-title, .fc-event.fc-event-nc-cancelled .fc-event-title,
.fc-event.fc-event-nc-cancelled .fc-list-event-title {
  text-decoration: line-through !important;
}
.fc-event .fc-event-title {
  text-overflow: ellipsis;
}
.fc-event .fc-event-nc-alarms .icon-event-reminder {
  background-color: inherit;
  background-position: right;
  position: absolute;
  top: 0;
  right: 0;
}
.fc-event .fc-event-nc-alarms .icon-event-reminder--light {
  background-image: var(--icon-calendar-reminder-fffffe);
}
.fc-event .fc-event-nc-alarms .icon-event-reminder--dark {
  background-image: var(--icon-calendar-reminder-000001);
}
.fc-event .fc-event-title-container {
  display: flex;
  align-content: center;
}
.fc-event .fc-event-title-container .fc-event-title-checkbox {
  margin: 4px 4px 0 0;
  line-height: 1;
}
.fc-event .fc-list-event-checkbox {
  margin: 2px 4px 0 -2px;
  line-height: 1;
}
.fc-event .fc-daygrid-event-checkbox {
  margin: 2px 4px 0 4px;
  line-height: 1;
}
.fc-event .fc-list-event-location span,
.fc-event .fc-list-event-description span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  max-width: 25vw;
}
@media only screen and (max-width: 767px) {
  .fc-event .fc-list-event-location,
  .fc-event .fc-list-event-description {
    display: none;
  }
}

.fc-list-empty .fc-list-empty-cushion {
  display: none;
}
.fc-list-empty .empty-content {
  margin-top: 0 !important;
}

.fc-col-header-cell {
  word-break: break-word;
  white-space: normal;
}

.fc-timeGridWeek-view .fc-daygrid-more-link {
  word-break: break-all;
  white-space: normal;
}
.fc-timeGridWeek-view .fc-event-main {
  flex-wrap: wrap;
}

.fc-timegrid-col-events {
  margin: 0 !important;
}

.fc-v-event.fc-timegrid-event-short {
  min-height: 2em;
}
.fc-v-event .fc-event-title {
  white-space: initial;
}

.fc-dayGridMonth-view .fc-daygrid-more-link {
  word-break: break-word;
  white-space: normal;
}
.fc-dayGridMonth-view .fc-daygrid-day-frame {
  min-height: 150px !important;
}

.fc-daygrid-day-events {
  position: relative !important;
}

.fc-col-header-cell {
  padding-top: 10px !important;
}

.fc-timegrid-axis-cushion {
  margin-top: 44px;
}

.fc-timegrid-axis.fc-scrollgrid-shrink {
  height: 65px;
}

.fc-timegrid-event-harness, .fc-timegrid-event {
  margin-bottom: 0;
}

/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.toast-calendar-multiline {
  white-space: pre-wrap;
}

.content.app-calendar > div.app-content {
  overflow-x: hidden;
}

.app-navigation-spacer {
  list-style-type: none;
}

/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.import-modal .modal-container {
  padding: 24px !important;
  min-width: 50%;
  overflow: visible !important;
}
.import-modal .modal-container .import-modal__title,
.import-modal .modal-container .import-modal__subtitle {
  text-align: center;
}
.import-modal .modal-container .import-modal__actions {
  display: flex;
  gap: 5px;
}
.import-modal .modal-container .import-modal-file-item {
  display: flex;
  padding-top: 10px;
}
.import-modal .modal-container .import-modal-file-item--header {
  font-weight: bold;
}
.import-modal .modal-container .import-modal-file-item__filename {
  flex: 2 1 0;
}
.import-modal .modal-container .import-modal-file-item__calendar-select {
  flex: 1 1 0;
}

/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
@media print {
  .app-navigation {
    display: none;
  }
}
/*!
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
#emptycontent-container #emptycontent {
  color: #a9a9a9 !important;
}

.content.app-calendar.app-calendar-public-embedded {
  flex-direction: column;
}
.content.app-calendar.app-calendar-public-embedded #embed-header {
  height: 50px;
  width: 100%;
  padding: calc(var(--default-grid-baseline) * 2);
  box-sizing: border-box;
  background-color: var(--color-main-background);
  border-bottom: 1px solid var(--color-border);
  overflow: visible;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content.app-calendar.app-calendar-public-embedded #embed-header .embed-header__date-section,
.content.app-calendar.app-calendar-public-embedded #embed-header .embed-header__share-section {
  display: flex;
  gap: 5px;
}
.content.app-calendar.app-calendar-public-embedded #embed-header .view-button-section .button {
  min-width: 75px;
}
.content.app-calendar.app-calendar-public-embedded #embed-header .datepicker-button-section__datepicker-label {
  min-width: 150px;
}
.content.app-calendar.app-calendar-public-embedded #embed-header .datepicker-button-section button,
.content.app-calendar.app-calendar-public-embedded #embed-header .today-button-section button,
.content.app-calendar.app-calendar-public-embedded #embed-header .view-button-section button {
  margin: 0;
}
.content.app-calendar.app-calendar-public-embedded .app-content {
  flex-basis: auto;
}

#body-public input#initial-state-calendar-is_embed ~ header#header {
  display: none;
}
#body-public .app-calendar-public + footer {
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
}
#body-public .app-calendar-public .app-content {
  height: calc(100% - 65px) !important;
}

/*!
 * SPDX-FileCopyrightText: 2023 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
.property-text__input--linkify {
  flex-basis: min-content;
}

.linkify-links {
  border: 2px solid var(--color-border-maxcontrast);
  border-radius: var(--border-radius-large);
  cursor: text;
  width: 100% !important;
  box-sizing: border-box;
  padding: 12px;
  white-space: pre-line;
  overflow: auto;
  line-height: normal;
  word-break: break-word;
  display: inline-block;
  vertical-align: top;
  max-height: 16em;
  max-height: calc(100vh - 500px);
}
.linkify-links a.linkified {
  text-decoration: underline;
  margin: 0;
}
.linkify-links a.linkified::after {
  content: " ↗";
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
